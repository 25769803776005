// Breadcrumbs
.breadcrumb-item {
  display: flex;
  color: $gray;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  letter-spacing: 1.3px;
  text-transform: capitalize;

  @include media-breakpoint-up("xl") {
    font-size: $breadcrumb-font-size-sm;
  }

  @include media-breakpoint-up("xxl") {
    font-size: $breadcrumb-font-size;
  }

  a {
    color: $gray;

    &.active {
      color: $secondary-dark;
    }
  }

  &+& {
    padding-left: $breadcrumb-item-padding-sm;

    @include media-breakpoint-up("xxl") {
      padding-left: $breadcrumb-item-padding;
    }

    &::before {
      padding-right: $breadcrumb-item-padding-sm;

      @include media-breakpoint-up("xxl") {
        padding-right: $breadcrumb-item-padding;
      }
    }
  }

  &:nth-last-child(2) {
    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include media-breakpoint-up("xl") {
        max-width: 180px;
      }

      @include media-breakpoint-down("lg") {
        max-width: 100px;
      }
    }
  }
}