// font weight for semibold 
.font-weight-semibold {
  font-weight: $font-weight-semibold;
}
// font weight for medium 
.font-weight-medium {
  font-weight: $font-weight-medium;
}
.text-transform-none {
  text-transform: none;
}