// All the section Title Separator
.title-separator {
  width: 30px;
}

.block {
  padding: 4rem 0 2rem 0;

  .block-title {
    font-weight: $font-weight-bold;
    letter-spacing: 2px;
    color: $primary;
    text-transform: uppercase;
  }

  .block-desc {
    padding: 1rem 0;
    text-align: center;

    color: $gray;
  }
}

// Icon Style
.icon {
  font-size: $input-btn-font-size-sm;

  @include media-breakpoint-up("xxl") {
    font-size: $font-size-base * 0.875;
  }

  // Icon sizes
  @each $size, $value in $icon-size {
    &.icon-#{$size} {
      font-size: $value;
    }
  }
}

// Chat Area
.chat-box {
  position: fixed;
  top: $chat-box-top-position-sm;
  right: 0;
  bottom: 0;
  width: $chat-box-width;
  max-width: 100%;
  z-index: 9;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  @include transition($transition-base);

  @include media-breakpoint-up("xxl") {
    top: $chat-box-top-position;
  }

  .chat-area {
    .user-initial {
      height: $chat-user-initial-size;
      width: $chat-user-initial-size;
    }
  }

  .date,
  .time,
  .username {
    font-size: $font-size-base * 0.65;
  }

  .username {
    color: $gray-dark;
  }

  .message {
    display: inline-block;
    font-size: $font-size-base * 0.8;
  }

  .odd {
    .date {
      padding-left: $chat-user-initial-size;
    }

    .message-box {
      border-bottom-left-radius: 0;
    }
  }

  .even {
    .date {
      padding-right: $chat-user-initial-size;
    }

    .message-box {
      background-color: $gray-light;
      border-bottom-right-radius: 0;
    }
  }

  .chat-footer {
    // input height for chat
    $input-height: 45px;

    .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: $border-radius;
      height: $input-height;
    }

    .btn-icon {
      height: $input-height;
      width: $input-height;
    }
  }
}

.message-box {
  display: inline-block;
  border-radius: 10px;

  &+& {
    margin-top: map-get($spacers, 1);
  }
}

// Note Text
.note {
  .small {
    @include media-breakpoint-down("lg") {
      font-size: $font-size-base * 0.75;
    }
  }
}

//fleet
.fleet-model-title {
  font-size: $font-size-base * 1.1;
  text-transform: uppercase;
}

// Room
.room-layout-img {
  img {
    height: $room-layout-img-height;
    width: auto;
  }
}

.room-layout {
  flex-basis: 130px;
  flex-grow: 1;
  max-width: 140px;

  @include media-breakpoint-up("xxl") {
    width: 145px;
  }

  .custom-control-label {

    &::after,
    &::before {
      left: auto;
      right: 5px;
    }
  }

  .layout-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    max-width: 100px;
  }
}

// Archived Modal
.archived-modal {
  pointer-events: none;
}

// Report Gallery Modal
.gallery-content {
  position: relative;

  .gallery-images {
    height: 300px;
  }

  .gallery-navigation {
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transition: $transition-base;

      &.prev-btn {
        left: 20px;
        transform: translateY(-50%) translateX(10px);
      }

      &.next-btn {
        right: 20px;
        transform: translateY(-50%) translateX(-10px);
      }
    }
  }

  &:hover {
    .gallery-navigation {
      .btn {
        transform: translateY(-50%) translateX(0);
      }
    }
  }
}

// Modal
.modal {
  pointer-events: none;
}

// OTP conataier
.otp-container {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  width: 35%;
  min-width: 280px;
  height: 45%;
  min-height: 370px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba($black, 0.2);
  padding: 2rem;
}

.opt-header {
  font-size: 1.2rem;
  font-weight: $font-weight-semibold;
  text-align: center;
  color: $gray-800;
}

.otp-header-logo {
  width: 18%;
  min-width: 200px;
}

.otp-number-input {
  text-align: center;
  letter-spacing: 0.8rem;
  font-weight: $font-weight-semibold;
}

.scan-doc-box {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.link-ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-link-ellipsis {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// icons class
.icon-size-medium {
  font-size: $h4-font-size;
}

.icon-document {
  width: 26px;
}

.fixed-bottom-button {
  position: absolute;
  bottom: 20px;
  right: 75px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  margin-left: 150px;
}