::-webkit-scrollbar {
    width: 7px;
    height: 4px;

    &-thumb {
        background-color: #c1c1c1;
        border-radius: 12px;
    }

    &-button {
        visibility: hidden;
        height: 4px;
        width: 7px;

        &:vertical:start:increment,
        &:vertical:end:decrement,
        &:horizontal:start:increment,
        &:horizontal:end:decrement {
            display: none;
        }
    }
}
