.drag-handle {
  display: flex;
  align-items: center;
  cursor: move;

  .handle {
    margin-right: 5px;
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: $border-radius;
  box-shadow: $box-shadow-sm;
  padding: 4px;
}
.cdk-drag-placeholder {
  opacity: 0;
}

.example-box {
  cursor: move;
  background-color: $white;
  &.cdk-drag-preview {
    td {
      padding: $table-cell-padding;
      font-size: $td-font-size;
      vertical-align: top;
      text-align: left;

      @include media-breakpoint-down("xl") {
        font-size: $font-size-base * 0.8;
        padding: 0.6rem 0.5rem;
      }
      @include media-breakpoint-down("lg") {
        font-size: $font-size-base * 0.75;
        padding: 0.6rem 0.275rem;
      }
  
      &:nth-child(1) {
        min-width: 120px;
        max-width: 100%;
        width: 15%;
      }
      &:nth-child(2) {
        min-width: 100px;
        max-width: 100%;
        width: 10%;
      }
      &:nth-child(3) {
        min-width: 120px;
        max-width: 100%;
        width: 16%;
      }
      &:nth-child(4) {
        min-width: 120px;
        max-width: 100%;
        width: 15%;
      }
      &:nth-child(5) {
        min-width: 200px;
        max-width: 100%;
        width: 25%;
      }
      &:nth-child(6) {
        min-width: 130px;
        max-width: 100%;
        width: 30%;
      }
      &:nth-child(7) {
        text-align: center;
        min-width: 40px;
        max-width: 100%;
        width: 30%;
      }
    }
  }
}
